<template>
    <div>
        <h3 class="mb-2">Alerts</h3>
        <v-alert
            border="top"
            color="error darken-2"
            dark
            outlined
            prominent
            icon="mdi-alert"
        >
            <ul style="list-style: none">
                <template v-for="(alert, index) in alerts">
                    <li :key="index">{{ alert }}</li>
                </template>
            </ul>
        </v-alert>
    </div>
</template>
<script>
export default {
    name: "credit-alert",
    components: {},
    props: {
        alerts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>