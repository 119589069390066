var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Alerts")]),
      _c(
        "v-alert",
        {
          attrs: {
            border: "top",
            color: "error darken-2",
            dark: "",
            outlined: "",
            prominent: "",
            icon: "mdi-alert"
          }
        },
        [
          _c(
            "ul",
            { staticStyle: { "list-style": "none" } },
            [
              _vm._l(_vm.alerts, function(alert, index) {
                return [_c("li", { key: index }, [_vm._v(_vm._s(alert))])]
              })
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }